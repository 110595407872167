.orgchart {
  background-color: white !important;
  background-image: none !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 20px 40px !important;
}

.orgchart ul li .node:hover {
  background-color: #fff2db !important;
}

.orgchart .node.focused {
  background-color: unset !important;
}

.orgchart .hierarchy::before {
  border-top: 2px solid #ff9800 !important;
}

.orgchart > ul > li > ul li > .node:not(.outsider)::before,
.orgchart .hierarchy:not(.couple) > .node:has(+ .nodes)::after {
  background-color: #ff9800 !important;
}

.orgchart .node .edge::before {
  border-color: #ff980050 !important;
}

.orgchart .node .edge:hover::before {
  border-color: #ff9800 !important;
}

.edge.verticalEdge.topEdge {
  display: none !important; /* Hide the top arrow for all nodes */
}

.node .card-form {
  margin-bottom: 0 !important;
}

#chart-container {
  position: relative;
  height: 420px;
  border: 1px solid #aaa;
  margin: 0.5rem;
  overflow: auto;
  text-align: center;
  touch-action: none;
}
